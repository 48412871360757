import React, { Component, Fragment } from 'react'
import { ToursProps, ToursState, QuotationModel } from './types'
import { Container, Row, Col, Alert } from 'reactstrap'
import Formsy from 'formsy-react'
import 'styles/lightbox.css'
import 'react-lightbox-component/build/css/index.css'

import vanHero from 'img/vanhero.png'
import viajecomodo from 'img/viajecomodo.jpg'
import sinaburrimiento from 'img/sinaburrimiento.jpg'
import opcionsegura from 'img/opcionsegura.png'
import chofer from 'img/chofer.svg'
import tecnologia from 'img/tecnologia.svg'
import profesionalismo from 'img/profesionalismo.svg'

import galeria1 from 'img/galeria1.png'
import galeria2 from 'img/galeria2.png'
import galeria3 from 'img/galeria3.png'
import galeria4 from 'img/galeria4.png'

import 'styles/tours.css'
import { InputFormsy, SelectFormsy, DatepickerFormsy, CheckboxFormsy } from 'shared/components/formsy'
import moment, { Moment } from 'moment'
import { MailService } from 'services'
import { SendToContactBody } from 'services/MailService/types'
import { ApiErrors } from 'core/services/ApiService/types'

const startDate = moment()
const maxDate = moment().add(8, 'months')

const modelToHtml = (model: QuotationModel) => {
  return `
    Detalles del formulario de cotización:<br /><br />
    Nombre(s): <br />${model.name}<br /><br />
    Apellidos: <br />${model.last_name}<br /><br />
    Correo electrónico: <br />${model.email}<br /><br />
    Teléfono: <br />${model.phone}<br /><br />
    Origen del viaje: <br />${model.origin}<br /><br />
    Destino del viaje: <br />${model.destination}<br /><br />
    Número de pasajeros: <br />${model.passengers}<br /><br />
    Fecha de salida: ${model.departure.format('L')} <br /><br />
    Fecha de regreso: <br />${model.return.format('L')} <br /><br />
    ${
      model.chofer
        ? 'Servicio de chofer: La cotización SÍ incluye chofer <br />'
        : 'Servicio de chofer: La cotización NO incluye chofer <br />'
    }
  `
}

let LightBox: any = null

export class ToursLayout extends Component<ToursProps, ToursState> {
  form: any
  state: ToursState = {
    canSubmit: false,
    submitting: false,
    returnMinDate: moment()
  }

  componentDidMount() {
    import('react-lightbox-component')
      .then(component => {
        LightBox = component.default
        this.setState({})
      })
      .catch(err => {
        console.error(err)
      })
  }

  onSubmit = (model: QuotationModel) => {
    const body: SendToContactBody = {
      subject: 'Cotización desde la página',
      html_content: modelToHtml(model)
    }
    this.setState({ submitting: true, tourError: '', tourSucceded: false })
    MailService.sendToContact(body)
      .then(_res => {
        this.form.reset()
        this.setState({ submitting: false, tourSucceded: true })
      })
      .catch(err => {
        const newState: any = { submitting: false }
        if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
          newState.tourError = err.message
        } else {
          newState.tourError = 'No se ha podido cotizar su renta. Intente de nuevo más tarde.'
          console.error(err)
        }
        this.setState(newState)
      })
  }

  onValidForm = () => {
    this.setState({ canSubmit: true })
  }

  onInvalidForm = () => {
    this.setState({ canSubmit: false })
  }

  handleDepartureDate = (date: Moment) => {
    this.setState({ returnMinDate: date })
  }

  handleToggleAlert = () => {
    this.setState({ tourError: '', tourSucceded: false })
  }

  render() {
    const { returnMinDate, canSubmit, submitting, tourError, tourSucceded } = this.state
    return (
      <Fragment>
        <Container fluid className="hero">
          <Row>
            <Col sm={{ size: 3, offset: 1 }} xs={12}>
              <img src={vanHero} className="vanresponsiva" />
            </Col>
            <Col sm={{ size: 6, offset: 2 }} xs={12} className="text-justify caption">
              <h1 className="big-tours-white">Abordo Tours</h1>
              <h5>La opción más cómoda y segura para tus viajes vacacionales, empresariales, escolares o de excursiones.</h5>
            </Col>
          </Row>
        </Container>
        <Container>
          <Formsy
            ref={(node: any) => {
              this.form = node
            }}
            onValidSubmit={this.onSubmit}
            onValid={this.onValidForm}
            onInvalid={this.onInvalidForm}
          >
            <Row>
              <Col sm={12}>
                <p className="bluep">
                  All Abordo nace de la motivación por mejorar los servicios de transporte de pasajeros, ofreciendo una mejor alternativa en
                  comodidad, seguridad y confianza.
                </p>
              </Col>
              <Col sm={6}>
                <InputFormsy
                  name="name"
                  label="Nombre(s)"
                  labelClassName="bluep"
                  inputProps={{
                    placeholder: 'Escribe tu(s) nombre(s)',
                    disabled: submitting
                  }}
                  validations={{ isSpecialWords: true }}
                  validationErrors={{ isSpecialWords: 'El texto introducido no es un nombre válido' }}
                  required
                />
              </Col>
              <Col sm={6}>
                <InputFormsy
                  name="last_name"
                  label="Apellido(s)"
                  labelClassName="bluep"
                  inputProps={{
                    placeholder: 'Escribe tu(s) apellido(s)',
                    disabled: submitting
                  }}
                  validations={{ isSpecialWords: true }}
                  validationErrors={{ isSpecialWords: 'El texto introducido no es un apellido válido' }}
                  required
                />
              </Col>
              <Col sm={6}>
                <InputFormsy
                  name="email"
                  label="Correo electrónico"
                  labelClassName="bluep"
                  inputProps={{
                    placeholder: 'Escribe tu correo electrónico',
                    disabled: submitting
                  }}
                  validations={{
                    isEmail: true
                  }}
                  validationErrors={{
                    isEmail: 'El texto introducido no es un correo electrónico válido'
                  }}
                  required
                />
              </Col>
              <Col sm={6}>
                <InputFormsy
                  name="phone"
                  label="Teléfono"
                  labelClassName="bluep"
                  inputProps={{
                    placeholder: 'Escribe tu teléfono',
                    disabled: submitting,
                    maxLength: 10
                  }}
                  validations={{
                    isNumeric: true,
                    minLength: 10
                  }}
                  validationErrors={{
                    isNumeric: 'El número introducido no es un teléfono válido',
                    minLength: 'El número introducido no tiene una longitud válida'
                  }}
                  required
                />
              </Col>
              <Col sm={6}>
                <InputFormsy
                  name="origin"
                  label="Origen"
                  labelClassName="bluep"
                  inputProps={{
                    placeholder: 'Escribe tu origen',
                    disabled: submitting
                  }}
                  required
                />
                <InputFormsy
                  name="destination"
                  label="Destino"
                  labelClassName="bluep"
                  inputProps={{
                    placeholder: 'Escribe tu destino',
                    disabled: submitting
                  }}
                  required
                />
              </Col>
              <Col sm={6}>
                <SelectFormsy
                  name="passengers"
                  label="Pasajeros"
                  labelClassName="bluep"
                  selectProps={{
                    className: 'custom-select',
                    disabled: submitting
                  }}
                  value=""
                  required
                >
                  <option value="">Seleccionar número de pasajeros</option>
                  <option value="De 1 a 5 pasajeros">1 a 5</option>
                  <option value="De 5 a 10 pasajeros">5 a 10</option>
                  <option value="De 10 a 18 pasajeros">10 a 18</option>
                </SelectFormsy>
                <Row>
                  <Col sm={6}>
                    <DatepickerFormsy
                      name="departure"
                      label="Fecha de salida"
                      labelClassName="bluep"
                      onChange={this.handleDepartureDate}
                      pickerProps={{ maxDate, placeholderText: 'Elige tu fecha de salida', minDate: startDate, disabled: submitting }}
                      required
                    />
                  </Col>
                  <Col sm={6}>
                    <DatepickerFormsy
                      name="return"
                      label="Fecha de regreso"
                      labelClassName="bluep"
                      pickerProps={{
                        maxDate,
                        minDate: returnMinDate,
                        openToDate: returnMinDate,
                        placeholderText: 'Elige tu fecha de regreso',
                        disabled: submitting
                      }}
                      required
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={12} className="text-center">
                <br />
                <CheckboxFormsy name="chofer" label="¿Incluir chofer?" labelClassName="bluep" />
                {submitting ? (
                  <Fragment>
                    <br />
                    <span>
                      <i className="fa fa-spin fa-spinner" /> Cotizando renta...
                    </span>
                  </Fragment>
                ) : null}
                {tourError || tourSucceded ? (
                  <Alert
                    color={tourError ? 'danger' : 'success'}
                    isOpen={Boolean(tourError || tourSucceded)}
                    toggle={this.handleToggleAlert}
                    className="d-inline-block"
                  >
                    {tourError || 'ABORDO se contactará con usted.'}
                  </Alert>
                ) : null}
                <br />
                <button className="btn-submit-tours" type="submit" disabled={!canSubmit}>
                  Cotizar
                </button>
              </Col>
            </Row>
          </Formsy>
        </Container>
        <Container className="caracteristicas pad100" id="caracteristicas">
          <Row>
            <Col sm={12} xs={12} className="text-center">
              <h1 className="big-tours-pink">Características</h1>
            </Col>
            <Col sm={6} xs={12}>
              <h4>Viaja cómodamente</h4>
              <p className="grayp">
                Pueden viajar hasta 18 personas en nuestras van, con luz interior para lectura, asientos reclinables, cargador USB en todas
                las filas y un amplio maletero para disfrutar el viaje. Además de que puedes incluir un chofer altamente capacitado.
              </p>
            </Col>
            <Col sm={6} xs={12}>
              <img src={viajecomodo} className="img-fluid img-car" />
            </Col>
            <Col sm={6} xs={12} className="d-none d-sm-block">
              <img src={sinaburrimiento} className="img-fluid" />
            </Col>
            <Col sm={6} xs={12}>
              <h4>No más viajes aburridos</h4>
              <p className="grayp">
                Disfruta de tus películas o series favoritas en nuestra pantalla de 32” y escucha las mejores canciones en altavoces con
                audio envolvente, por medio del estéreo Bluetooth y USB.
              </p>
            </Col>
            <Col sm={6} className="d-block d-sm-none">
              <img src={sinaburrimiento} className="img-fluid img-car" />
            </Col>
            <Col sm={6} xs={12}>
              <h4>La opción más segura</h4>
              <p className="grayp">
                Viaja tranquilo, nuestra van cuenta con sensores de proximidad, cámara para reversa, cinturón de seguridad de tres puntos,
                retrovisor doble espejo sin puntos ciegos. Además cuentas con póliza de seguro para pasajeros y seguro vehicular tanto
                nacional como para Estados Unidos*.
              </p>
            </Col>
            <Col sm={6} xs={12}>
              <img src={opcionsegura} className="img-fluid img-car" />
            </Col>
            <Col sm={4} className="d-none d-sm-block text-center">
              <img src={chofer} className="img-fluid" />
              <h4>Operadores altamente capacitados</h4>
            </Col>
            <Col className="d-none d-sm-block text-center">
              <img src={tecnologia} className="img-fluid" />
              <h4>Tecnología de punta</h4>
            </Col>
            <Col sm={4} className="d-none d-sm-block text-center">
              <img src={profesionalismo} className="img-fluid" />
              <h4>Profesionalismo</h4>
            </Col>
          </Row>
        </Container>

        <Container fluid className="mancha-video pad100">
          <Row>
            <Container>
              <Row className="video">
                <Col sm={12} className="d-none d-sm-block" style={{ paddingBottom: 30 }}>
                  <iframe
                    width="100%"
                    height="620"
                    src="https://www.youtube.com/embed/OrkTqNNg2KY?controls=0"
                    frameBorder="0"
                    style={{ border: 'none', overflow: 'hidden' }}
                    scrolling="no"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Col>
                <Col sm={12} className="d-block d-sm-none" style={{ paddingBottom: 30 }}>
                  <iframe
                    width="100%"
                    height="620"
                    src="https://www.youtube.com/embed/OrkTqNNg2KY?controls=0"
                    frameBorder="0"
                    style={{ border: 'none', overflow: 'hidden' }}
                    scrolling="no"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </Col>
                <Container>
                  {LightBox ? (
                    <LightBox
                      renderImageFunc={(
                        idx: number,
                        image: { src: string },
                        toggleLightbox: (id: number) => void,
                        _width: number,
                        _height: number
                      ) => {
                        return (
                          <Col sm={3} xs={12} className="text-center" key={idx}>
                            <img className="example-image w-100 pointer" src={image.src} onClick={toggleLightbox.bind(null, idx)} alt="" />
                          </Col>
                        )
                      }}
                      images={[
                        {
                          src: galeria1,
                          description: ''
                        },
                        {
                          src: galeria2,
                          description: ''
                        },
                        {
                          src: galeria3,
                          description: ''
                        },
                        {
                          src: galeria4,
                          description: ''
                        }
                      ]}
                    />
                  ) : null}
                </Container>
              </Row>
            </Container>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

export default ToursLayout
